import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Button,
  Typography,
  message,
  Checkbox,
  Col,
} from 'antd';
import axios from 'axios';
import GoogleAddressInput from './CustomInputs';
import { useHistory } from 'react-router-dom';
import './formStyle.scss';
import { Link } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';

const { Title } = Typography;
const { Option } = Select;

export default function RegisterPlace() {
  const [Industries, setIndustries] = useState([]);
  const [Areas, setAreas] = useState([]);
  const onSubmit = (data) => {
    console.log(data);
  }; // your form submit function which will invoke after successful validation

  const functions = [
    'Besitzer',
    'Manager',
    'Chef',
    'Berater',
    'Operations/IT',
    'Lieferant',
    'Technologie Partner',
    'Anderes',
  ];

  const discounts = ['10', '15', '20'];

  useEffect(() => {
    axios.get('/api/places/industries').then((result) => {
      setIndustries(result.data.Industries);
    });
    axios.get('/api/places/areas', {}).then(
      (result) => {
        setAreas(result.data.Areas);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };
  const longLabelFormItemLayout = {
    labelCol: {
      xs: { span: 8, pull: 0 },
      sm: { span: 12, pull: 1 },
      md: { span: 12, pull: 2 },
      lg: { span: 12, pull: 3 },
    },
    wrapperCol: {
      xs: {
        span: 6,
        pull: 0,
      },
      sm: {
        span: 6,
        pull: 1,
      },
      md: {
        span: 6,
        pull: 2,
      },
      lg: {
        span: 6,
        pull: 3,
      },
    },
  };

  const SubmitForm = (props) => {
    const [form] = Form.useForm();
    const hist = useHistory();

    const onFinish = (values) => {
      console.log(values);

      axios
        .post('/api/places/register_new_place', {
          ...values,
          submitterName:
            values.submitterLastName + ', ' + values.submitterFirstName,
          plz: values.address.plz,
          manualAddress: values.address.street,
          addressArea: values.address.area,
        })
        .then((response) => {
          message.success('Registrierung abgesendet');
          hist.push('/registerPlaceSuccessful');
        })
        .catch((error) => {
          message.error('Fehler beim absenden!');
          console.log(error);
        });
    };

    return (
      <Form
        {...formItemLayout}
        form={form}
        name='register'
        onFinish={onFinish}
        initialValues={{
          discount: discounts[1],
        }}
        scrollToFirstError>
        <Form.Item
          name='placeName'
          label='Firmen Name'
          rules={[
            {
              required: true,
              message: 'Bitte den Namen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item name='placeAddress' label='Finde Deinen Betrieb'>
          <GoogleAddressInput></GoogleAddressInput>
        </Form.Item>
        {/*  <Form.Item
          name='manualAddress'
          label='Firmen Adresse'
          dependencies={['placeAddress']}
          rules={[
            {
              required: true,
              message: 'Please input the address!',
            },
          ]}>
          <Input />
        </Form.Item> */}
        <Form.Item label='Adresse' required='true'>
          <Input.Group compact>
            <Form.Item
              name={['address', 'street']}
              noStyle
              rules={[
                { required: true, message: 'Bitte die Straße eintragen!' },
              ]}>
              <Input style={{ width: '50%' }} placeholder='Straße' />
            </Form.Item>
            <Form.Item
              name={['address', 'plz']}
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Bitte die PLZ eintragen!',
                },
              ]}>
              <Input style={{ width: '20%' }} placeholder='PLZ' />
            </Form.Item>
            <Form.Item
              name={['address', 'area']}
              noStyle
              rules={[{ required: true, message: 'Bitte den Ort eintragen!' }]}>
              <Input style={{ width: '30%' }} placeholder='Ort' />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item name='placeWebsite' label='Firmen Webseite'>
          <Input />
        </Form.Item>
        <Form.Item name='placeDescription' label='Firmen-Beschreibung'>
          <TextArea></TextArea>
        </Form.Item>
        <Form.Item
          name='area'
          label='Kanton'
          rules={[
            {
              required: true,
              message: 'Bitte einen Kanton auswählen!',
            },
          ]}>
          <Select>
            {props.areas.map((ar) => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='industry'
          label='Kategorie'
          rules={[
            {
              required: true,
              message: 'Bitte eine Kategorie auswählen!',
            },
          ]}>
          <Select>
            {props.industries.map((ar) => {
              return <Select.Option value={ar.key}>{ar.name}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='submitterFirstName'
          label='Dein Vorname'
          rules={[
            {
              required: true,
              message: 'Bitte Deinen Vornamen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='submitterLastName'
          label='Dein Name'
          rules={[
            {
              required: true,
              message: 'Bitte deinen Nachnamen eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          name='email'
          label='Deine E-mail'
          rules={[
            /* {
              type: 'email',
              message: 'Die E-Mail ist nicht valide',
            }, */
            {
              required: true,
              message: 'Bitte Deine E-Mail eintragen!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item name='phoneNo' label='Tel. Nr.'>
          <Input />
        </Form.Item>
        <Form.Item
          name='submitterPosition'
          label='Deine Funktion'
          rules={[
            {
              required: true,
              message: 'Bitte Deine Funktion auswählen',
            },
          ]}>
          <Select>
            {functions.map((func) => {
              return <Select.Option value={func}>{func}</Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name='giftCardsNew'
          label='Geschenkkartenverkauf ist neu für mich'
          style={{ wordWrap: 'break-word', textAlign: 'left' }}
          valuePropName='checked'
          {...longLabelFormItemLayout}>
          <Checkbox></Checkbox>
        </Form.Item>
        <p>
          <b>Gutsein-Gutschein Einstellungen</b>
        </p>
        <p>
          Du kannst optional die verfügbare Menge pro Gutscheinbetrag begrenzen
          (z.B. nur 25 Stück verfügbar) und/oder ein Limit pro Bestellung setzen
          (z.B. kann nur 1 Stück pro Bestellung gekauft werden).
        </p>
        <Row>
          <Col span={12}>
            <Form.Item
              name='amountChf20'
              className='form-inline-input'
              label='Menge a CHF 20'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='limitChf20'
              label='Limit pro Bestellung'
              className='form-inline-input'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name='amountChf50'
              className='form-inline-input'
              label='Menge a CHF 50'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='limitChf50'
              label='Limit pro Bestellung'
              className='form-inline-input'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name='amountChf100'
              className='form-inline-input'
              label='Menge a CHF 100'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='limitChf100'
              label='Limit pro Bestellung'
              className='form-inline-input'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name='amountChf150'
              className='form-inline-input'
              label='Menge a CHF 150'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='limitChf150'
              label='Limit pro Bestellung'
              className='form-inline-input'
              rule={[
                {
                  validator: (_, value) => {
                    isNaN(value)
                      ? Promise.reject('Zahl eintragen!')
                      : Promise.resolve();
                  },
                },
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <p>
          <div className='ant-form-item-label '>
            <label
              className='ant-form-item-required'
              style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>
              Du verkaufst Deine Gutscheine zu einem Rabatt von
            </label>
          </div>
          <Form.Item
            name='discount'
            noStyle
            rules={[
              {
                required: true,
                message: 'Bitte Deinen Rabatt auswählen',
              },
            ]}>
            <Select style={{ width: 100, marginLeft: 10 }}>
              {discounts.map((func) => {
                return <Select.Option value={func}>{func}%</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </p>

        <p style={{ display: 'inline-block', marginLeft: 5 }}>
          VERFALL: Die Gutsein-Gutscheine verfallen automatisch 8 Monate nach
          Kauf. Möchtest Du den Verfall für Deine Gutsein-Gutscheine verlängern
          gib uns einfach Bescheid.
        </p>
        <br></br>
        <Form.Item
          name='agbs'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject('AGBs müssen akzeptiert werden!'),
            },
          ]}
          {...tailFormItemLayout}>
          <Checkbox>
            Ich bestätige die Richtigkeit der gemachten Angaben und habe die{' '}
            <Link to='/agb' target='_blank'>
              AGBs
            </Link>{' '}
            zur Kenntnis genommen und akzeptiere diese.
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type='primary' htmlType='submit'>
            Ich bin dabei, füge mich hinzu
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div style={{ width: '80%', maxWidth: '1100px', margin: '0 auto' }}>
      <div className='header-row subpage'>
        <Row justify='center' align='middle'>
          <Col xs={22} md={22}>
            <Title
              level={1}
              className='header-title-text'
              style={{ fontSize: '2em', padding: '10px 0' }}>
              Füge Dein Geschäft hinzu
            </Title>
            <div
              className='header-underheader'
              style={{
                textAlign: 'left',
              }}>
              Wir haben für Dich im Internet eine Plattform errichtet, über
              welche Du Deiner Kundschaft Wertgutscheine zu einem attraktiven
              Tarif anbieten kannst. Schlicht ein "Gutsein-Gutschein".
              <br />
              <br />
              Füge die Informationen Deines Geschäfts hinzu, um Deine Gäste zu
              ermutigen, in Dein Geschäft zu kommen. Nachdem Du Deine
              Informationen eingegeben hast, erhältst Du eine
              Bestätigungs-E-Mail, wenn Dein Geschäft dem Verzeichnis
              hinzugefügt wird. <br />
              <br />
              <ul className='logo-points'>
                <li>
                  Du brauchst kein eigenes Gutschein-System, die Plattform
                  übernimmt für Dich die Arbeit.
                </li>
                <li>Keine monatliche Gebühr oder Setup Kosten.</li>
                <li>Die Kunden kommen auf Dich zu.</li>
                <li>
                  Du kannst Kundenloyalität schaffen und aufrecht erhalten.
                </li>
                <li>Die Plattform bleibt auch nach der Krise in Betrieb.</li>
                <li>
                  Du kannst den Wert abgelaufener/unbenutzter Gutscheine
                  behalten.
                </li>
              </ul>
              Zur Deckung der Gebühren für Zahlungsverkehr, Servicekosten der
              Wertgutscheine und Administration verrechnen wir Total 7% auf den
              Gutscheinbetrag. (Dies beinhaltet externe Kosten für
              Zahlungsverkehr von 3-5%). Dieser Betrag wird bei der Auszahlung
              direkt abgezogen.
              <br />
              <br />
              <div style={{ color: 'red' }}>COVID-19 PROMOTION:</div> Für die
              ersten 50 Betriebe übernehmen wir als Initiative bis Ende Juni
              2020, die Service- und Administrationskosten der Gutscheine, bis
              zu kumulativ CHF 1'000 in Gutscheinwert pro Betrieb. Dann fallen
              nur noch 4.5% für den Zahlungsverkehr an. Wenn wir das verlängern
              müssen, werden wir das tun! Und es gibt keine Verpflichtung, uns
              darüber hinaus einzusetzen, wir wollen in der jetzigen Situation
              helfen! Unsere Plattform bleibt aber auch noch nach der Krise
              bestehen, mit weiteren neuartigen Funktionen für Dich!
            </div>
          </Col>
        </Row>
      </div>
      <div className='main-results' style={{ padding: 15 }}>
        <SubmitForm areas={Areas} industries={Industries}></SubmitForm>
        <Row>
          <Title level={3}>Und so funktionierts:</Title>
        </Row>
        <Row>
          <ol>
            <li>
              Melde Dich einfach über das Formular an und gebe uns die Details
              Deines Gutsein-Gutscheins bekannt.
            </li>
            <li>
              Du wirst umgehend registriert und erhältst eine Bestätigung,
              sobald Du aufgeschaltet bist.
            </li>
            <li>
              Nach jedem Kauf wirst Du sofort darüber informiert und erhältst
              eine Übersicht Deiner verkauften Gutsein-Gutscheine
            </li>
            <li>
              Gib uns Deine IBAN bekannt damit Du den Betrag durch den Verkauf
              sofort erhältst.
            </li>
          </ol>
          Alle Gutscheine sind Wertgutscheine und sind mit einem eindeutigen
          Code versehen, welcher im Moment der Einlösung von Deinem Betrieb
          geprüft werden kann. Der Code wird durch das Gutscheinsystem
          automatisch generiert und direkt auf dem Gutscheinausdruck eingefügt.
          So ist sichergestellt, dass der bestehende Wert auf einem
          Gutsein-Gutschein jederzeit geprüft und eingelöst werden kann.
        </Row>
      </div>
    </div>
  );
}
