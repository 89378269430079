import React, { useState } from 'react';
import { Form, Button, Steps, Col, Row, message } from 'antd';
import { StepOne, StepThree, StepTwo, StepFour, StepFive } from './Steps';
import axios from 'axios';
const { Step } = Steps;

const NextButton = (props) => {
  return <Button onClick={() => props.setStep(props.step + 1)}>Weiter</Button>;
};

const BackButton = (props) => {
  return <Button onClick={() => props.setStep(props.step - 1)}>Zurück</Button>;
};

const onFinish = (values) => {
  console.log(values);

  return;

  axios
    .post('/api/places/lawyerRequest', {
      ...values,
    })
    .then((response) => {
      message.success('Anfrage gesendet');
    })
    .catch((error) => {
      message.error('Fehler beim absenden!');
      console.log(error);
    });
};

const layout = {
  labelCol: {
    sm: {
      span: 24,
    },
    md: {
      span: 4,
    },
  },
  wrapperCol: {
    sm: {
      span: 24,
    },
    md: {
      span: 20,
    },
  },
};

function MasterForm() {
  const [step, setstep] = useState(1);
  const [formData, setformData] = useState({});

  return (
    <>
      <div className='lawyerMain'>
        <div className='steps' style={{ marginTop: 85 }}>
          <Steps current={step - 1}>
            <Step title='Willkommen'></Step>
            <Step title='Anmeldung'></Step>
            <Step title='Gebühren'></Step>
            <Step title='Dein Anliegen'></Step>
            <Step title='Zusammenfassung'></Step>
          </Steps>
        </div>
        <div className='lawyerForm'>
          <Form
            {...layout}
            layout='horizontal'
            className='lawyer-form'
            onValuesChange={(changedValues) =>
              setformData({ ...formData, ...changedValues })
            }
            onFinish={() => onFinish(formData)}>
            <StepOne currentData={formData} step={step}></StepOne>
            <StepTwo currentData={formData} step={step}></StepTwo>
            <StepThree
              currentData={formData}
              setData={setformData}
              step={step}></StepThree>
            <StepFour currentData={formData} step={step}></StepFour>
            <StepFive currentData={formData} step={step}></StepFive>
            <div
              style={{
                marginTop: 10,
                textAlign: 'start',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              {step > 1 && (
                <BackButton setStep={setstep} step={step}></BackButton>
              )}
              {step < 5 && (
                <NextButton setStep={setstep} step={step}></NextButton>
              )}
              {step == 5 && (
                <Button type='primary' htmlType='submit'>
                  Absenden
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
      <Row
        style={{
          width: '100%',
          height: '5.5em',
          backgroundColor: 'grey',
          marginTop: 20,
          zIndex: -1,
          paddingTop: '10px',
          color: 'whitesmoke',
          position: 'fixed',
          bottom: 0,
          textAlign: 'center',
        }}
        justify='space-between'>
        <Col span={8}>BENÖTIGEN SIE HILFE?</Col>
        <Col span={8}>Kontaktieren Sie uns mit einer Nachricht</Col>
        <Col span={8}>
          Rufen Sie an:
          <br />
          0784330835
        </Col>
      </Row>
    </>
  );
}

export default MasterForm;
